
































import {
    Component,
    Vue,
    Watch,
} from 'vue-property-decorator';
import {
    Core
} from '@/store/core'
import {
    Auth
} from '@/store/auth'
import {
    User
} from '@/store/user'

import moment from "moment";
import {
    FarmForm
} from "@/models/farm";

@Component({
    components: {},
    computed: {}
})

export default class ImageClass extends Vue {
    api: any = process.env.VUE_APP_SERVER
    private response: boolean = false
    private user: any = User.user
    private async created() {
        this.run();
    }

    private async run() {
        this.response = true
    }

    get userImage() {
        return this.api +'/'+ this.user.profile_image ;
    }

    async profileChange(event: any) {
        this.response = false
        let file = await this.getBase64(event.target.files[0])
        this.user.profile_image = file
        let data = await Core.putHttp(`/user/account/profile/image/${this.user.profile_id}/`, this.user)
        if (data.id) {
            await User.setUser();
            this.user = User.user
            alert("เปลี่ยนแปลงรูปภาพสำเร็จ")
        }
        this.response = true
    }

    async getBase64(file: any) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

}
