











































import {
    Component,
    Vue,
    Watch,
} from 'vue-property-decorator';
import {
    Core
} from '@/store/core'
import {
    Auth
} from '@/store/auth'
import {
    User
} from '@/store/user'
import moment from "moment";
import { FarmForm } from "@/models/farm";
import { App } from "@/store/app";

@Component({
    components: {},
    computed: {}
})

export default class ImageClass extends Vue {
    public form: FarmForm | any = {}
    public formProfile: any = {}
    private currentFarmer: any = null
  api: any = process.env.VUE_APP_SERVER
    private async created() {
        await this.collingPermission()
        await this.run();
    }

    async collingPermission() {
        let user = await User.getUser();
        let userAll = await Core.getHttp(`/api/account/${user.pk}/`)
        if (userAll.is_staff) {
            this.currentFarmer = this.$route.query.farmer
        } else {
            let profile = await Core.getHttp(`/user/account/myprofile/${user.pk}/`)
            this.currentFarmer = profile.id
        }
    }

    private async run() {
        await App.onLoad()
        this.formProfile = await Core.getHttp(`/user/account/personal/image/${this.currentFarmer}/`)
        this.form = await Core.getHttp(`/user/buffalo/farm/image/${this.formProfile.user}/`)
        if (this.formProfile.presonal_image) {
            let personalImage: any = this.$refs.personalImage
            personalImage.src = this.api +'/'+ this.formProfile.presonal_image
        }

        if (this.form.farm_image) {
            let farmImage: any = this.$refs.farmImage
            farmImage.src = this.api +'/'+  this.form.farm_image
        }
        await App.offLoad();
    }

    async farmChange(event: any) {
        console.log(event.target.files)
        let file = await this.getBase64(event.target.files[0])
        let farmImage: any = this.$refs.farmImage
        farmImage.src = file
        this.form.farm_image = file
        let data = await Core.putHttp(`/user/buffalo/farm/image/${this.form.id}/`, this.form)
        if (data.id) {
            await App.success("อัพโหลดรูปภาพสำเร็จ")
        }
    }

    async personalChange(event: any) {
        console.log(event.target.files)
        let file = await this.getBase64(event.target.files[0])
        let personalImage: any = this.$refs.personalImage
        personalImage.src = file
        this.formProfile.presonal_image = file
        let data = await Core.putHttp(`/user/account/personal/image/${this.formProfile.id}/`, this.formProfile)
        if (data.id) {
            await App.success("อัพโหลดรูปภาพสำเร็จ")
        }
    }

    async getBase64(file: any) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

}
