


























































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { Core } from "@/store/core";
import { Auth } from "@/store/auth";
import { User } from "@/store/user";

import { City } from "@/store/city";
import moment from "moment";
import { App } from "@/store/app";

@Component({
  components: {},
  computed: {},
})
export default class Profile extends Vue {
  private formUser: any = {};
  private formProfile: any = {};
  private prefix: any[] = [];
  private gender: any[] = [];
  private group: any[] = [];
  private currentFarmer: any = null;
  private unEdit: boolean = true;

  async created() {
    await this.collingPermission();
    await this.run();
  }

  async collingPermission() {
    let user = await User.getUser();
    let userAll = await Core.getHttp(`/api/account/${user.pk}/`);
    if (userAll.is_staff) {
      this.currentFarmer = this.$route.query.farmer;
    } else {
      let profile = await Core.getHttp(`/user/account/myprofile/${user.pk}/`);
      this.currentFarmer = profile.id;
    }
  }

  async run() {
    await App.onLoad();
    this.prefix = await Core.getChoice("คำนำหน้า");
    this.gender = await Core.getChoice("เพศ");
    this.group = await Core.getChoice("กลุ่มเกษตรกร");
    this.formProfile = await Core.getHttp(
      `/api/profile/${this.currentFarmer}/`
    );
    this.formUser = await Core.getHttp(
      `/api/account/${this.formProfile.user}/`
    );
    await this.setCity();
    await App.offLoad();
  }

  @Watch("formProfile.birthday")
  async onChangeProvince(val: string) {
    this.formProfile.age = moment().diff(val, "years", false);
  }

  async setCity() {
    City.currentGeo = await Core.getHttp(
      `/user/account/geography/${this.formProfile.geo}/`
    );
    City.currentProvince = await Core.getHttp(
      `/user/account/province/${this.formProfile.province}/`
    );
    City.currentAmphur = await Core.getHttp(
      `/user/account/amphur/${this.formProfile.amphur}/`
    );
    City.currentDistrict = await Core.getHttp(
      `/user/account/district/${this.formProfile.district}/`
    );
    await City.setShowName();
  }

  async update() {
    this.formProfile.geo = City.currentGeo?.id;
    this.formProfile.province = City.currentProvince?.id;
    this.formProfile.amphur = City.currentAmphur?.id;
    this.formProfile.district = City.currentDistrict?.id;
    let profile = await Core.putHttp(
      `/api/profile/${this.currentFarmer}/`,
      this.formProfile
    );
    let user = await Core.putHttp(
      `/api/account/${this.formProfile.user}/`,
      this.formUser
    );

    if (user.id && profile.id) {
      this.unEdit = true;
      await App.success("แก้ไขข้อมูลสำเร็จ");
    } else {
      await App.error("เกิดข้อผิดพลาดในหารแก้ไขข้อมูล");
    }

    await this.run();
  }

  async openCityDialog() {
    City.dialogCityState = true;
  }

  get CityFrom() {
    return City.showName;
  }
  get editMode() {
    return this.unEdit ? "border-success" : "border-edit";
  }
}
