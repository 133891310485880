


































































import {
    Component,
    Vue,
    Watch,
} from 'vue-property-decorator';
import {
    Core
} from '@/store/core'
import {
    Auth
} from '@/store/auth'
import {
    User
} from '@/store/user'

import moment from "moment";
import { FarmForm } from "@/models/farm";
import { App } from "@/store/app";

@Component({
    components: {},
    computed: {}
})

export default class ManagerClass extends Vue {
    public formPassword: object | any = {}
    public formUser: FarmForm | any = {}
    public formProfile: any = {}
    private currentFarmer: any = null

    private admin: any = null

    private async created() {
        await this.collingPermission()
        await this.run();
    }

    async collingPermission() {
        let user = await User.getUser();
        let userAll = await Core.getHttp(`/api/account/${user.pk}/`)
        if (userAll.is_staff) {
            this.admin = userAll.is_staff

            this.currentFarmer = this.$route.query.farmer
        } else {
            let profile = await Core.getHttp(`/user/account/myprofile/${user.pk}/`)
            this.currentFarmer = profile.id
        }
    }

    private async run() {
        await App.onLoad()
        this.formProfile = await Core.getHttp(`/api/profile/${this.currentFarmer}/`)
        this.formUser = await Core.getHttp(`/api/account/${this.formProfile.user}/`)
        await App.offLoad();
    }

    async changeStatus() {
        console.log(this.formProfile)
        let profile = await Core.postHttp(`/user/account/user/${this.formProfile.id}/`, this.formProfile)
        if (profile.id) {
            await App.success("จัดการสิทธิ์สำเร็จ")
        }
        await this.run();
    }

    async disableUser() {
        let check = await App.confirm('คุณแน่ใจใช่ไหม', 'ี่จะลบผู้ใช้คนนี้')
        if (check) {
            let user = await Core.deleteHttp(`/user/account/user/${this.formUser.id}/`)
            if (user.id) {
                await App.success("ลบผู้ใช้สำเร็จ")
                await this.$router.go(-1)
            }
        }
    }

    async changePassword(event: any) {
        if (this.formPassword.password == this.formPassword.password2) {
            let change = await Core.putHttp(`/user/account/user/${this.formUser.id}/`, this.formPassword)
            this.formPassword = {}
            if (change.id) {
                await App.success("เปลี่ยนรหัสผ่านสำเร็จ")
            }
        } else {
            await App.error("รหัสผ่านไม่ตรงกัน")
        }
    }

}
