

















































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { Core } from "@/store/core";

import { App } from "@/store/app";

import { Auth } from "@/store/auth";
import { User } from "@/store/user";

import { City } from "@/store/city";
import MapView from "@/components/Maps/MapView.vue";

import moment from "moment";
import { FarmForm } from "@/models/farm";
import { Map } from "@/store/map";

@Component({
  components: { MapView },
  computed: {},
})
export default class Farm extends Vue {
  public form: FarmForm | any = {};
  private response: boolean = false;
  private unEdit: boolean = true;
  private currentFarmer: any = null;
  private group: any[] = [];
  currentLocation: any = {};

  private async created() {
    await this.collingPermission();
    await this.run();
  }

  async collingPermission() {
    let user = await User.getUser();
    let userAll = await Core.getHttp(`/api/account/${user.pk}/`);
    console.log(user, userAll);
    // if (userAll.is_staff && this.$route.query.farmer) {
    //     this.currentFarmer = this.$route.query.farmer
    // } else {
    //     let profile = await Core.getHttp(`/user/account/myprofile/${user.pk}/`)
    //     this.currentFarmer = profile.id
    // }
    let profile = await Core.getHttp(`/user/account/myprofile/${user.pk}/`);
    this.currentFarmer = profile.id;
  }

  private async run() {
    await App.onLoad();
    this.group = await Core.getChoice("กลุ่มเกษตรกร");
    let profile = await Core.getHttp(`/api/profile/${this.currentFarmer}/`);
    this.form = await Core.getHttp(`/user/buffalo/farm/${profile.user}/`);
    await this.setCity();
    await this.generateMap();
    this.response = true;
    await App.offLoad();
  }

  async generateMap() {
    this.response = false;
    let map = this.form.location.split(",");
    if (map.length > 1) {
      this.currentLocation = {
        center: { Latitude: parseFloat(map[0]), Longitude: parseFloat(map[1]) },
        mark: [{ Latitude: parseFloat(map[0]), Longitude: parseFloat(map[1]) }],
      };
    }
  }

  async update() {
    this.response = false;
    this.form.geo = City.currentGeo?.id;
    this.form.province = City.currentProvince?.id;
    this.form.amphur = City.currentAmphur?.id;
    this.form.district = City.currentDistrict?.id;
    let farm = await Core.putHttp(
      `/api/buffalo/farm/${this.form.id}/`,
      this.form
    );
    if (farm.id) {
      this.unEdit = true;
      await App.success("แก้ไขข้อมูลสำเร็จ");
    } else {
      await App.error("เกิดข้อผิดพลาดในการแก้ไขข้อมูล");
    }
    await this.run();
  }

  @Watch("latCore")
  async changeMapCore() {
    this.form.location = `${Map.lat},${Map.lng}`;
    await this.update();
  }
  get latCore() {
    return Map.lat;
  }
  get lngCore() {
    return Map.lng;
  }

  async setCity() {
    if (this.form != null) {
      City.currentGeo = await Core.getHttp(
        `/user/account/geography/${this.form.geo}/`
      );
      City.currentProvince = await Core.getHttp(
        `/user/account/province/${this.form.province}/`
      );
      City.currentAmphur = await Core.getHttp(
        `/user/account/amphur/${this.form.amphur}/`
      );
      City.currentDistrict = await Core.getHttp(
        `/user/account/district/${this.form.district}/`
      );
      await City.setShowName();
    }
  }

  async openCityDialog() {
    City.dialogCityState = true;
  }

  get CityFrom() {
    return City.showName;
  }
  get editMode() {
    return this.unEdit ? "border-success" : "border-edit";
  }
}
