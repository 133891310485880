
























































































































































































import { Component, Vue } from "vue-property-decorator";

import Profile from "@/components/Component/User/Profile/Profile.vue";
import Farm from "@/components/Component/User/Profile/Farm.vue";
import ImageUser from "@/components/Component/User/Profile/Image.vue";
import Manager from "@/components/Component/User/Profile/Manager.vue";
import { Core } from "@/store/core";
import { Auth } from "@/store/auth";
import { User } from "@/store/user";

import CityDialog from "@/components/Dialog/City.vue";

@Component({
  components: {
    CityDialog,
    Profile,
    Farm,
    ImageUser,
    Manager,
  },
  computed: {},
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
})
export default class Table extends Vue {
  private openTab: number = 1;

  private async created() {
    await this.defaultTab();
  }

  async defaultTab() {
    let path: number = Number(this.$route.query.tab);
    return path ? await this.toggleTabs(path) : null;
  }
  async toggleTabs(tabNumber: number) {
    console.log(this.$route.fullPath);
    if (this.$route.query.tab != String(tabNumber)) {
      await this.$router.push({
        query: {
          tab: String(tabNumber),
        },
      });
    }
    this.openTab = tabNumber;
  }
}
